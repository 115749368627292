<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-gutter-y-sm">
        <div class="row items-center">
          <div style="width: 100px">Tanggal Awal</div>
          <q-input class="l-grow" outlined dense v-model="data.date_from">
            <q-popup-proxy>
              <q-date
                v-model="data.date_from"
                mask="YYYY-MM-DD"
                :options="optionsFrom"
              >
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="OK" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-input>
        </div>
        <div class="row items-center">
          <div style="width: 100px">Tanggal Akhir</div>
          <q-input
            class="l-grow"
            outlined
            dense
            :disable="!data.date_from"
            v-model="data.date_to"
          >
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                v-model="data.date_to"
                mask="YYYY-MM-DD"
                :options="optionsTo"
              >
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="OK" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-input>
        </div>
        <!-- <div class="row items-center">
          <div style="width: 100px"></div>
          <div class="text-negative">*Jarak maksimal adalah 31 hari</div>
        </div> -->
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat color="brand" label="Cancel" @click="onCancelClick" />
        <q-btn color="brand" label="Export" @click="exportExcell" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent } from "quasar";
import useBasic from "@/composables/useBasic";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default {
  props: [],

  emits: [...useDialogPluginComponent.emits],

  setup(props) {
    const {
      $http,
      onMounted,
      reactive,
      moment,
      waitThis,
      sumArrayofObject,
      excelColumn,
      getExcellColumn,
    } = useBasic();

    onMounted(async () => {
      await getDataGap();
    });

    let data = reactive({
      optStaff: [],

      dataGap: {
        min: moment().format("YYYY-MM-DD"),
        max: moment().format("YYYY-MM-DD"),
      },

      date_from: null,
      date_to: null,
    });

    let getDataGap = async () => {
      let resp = await $http.get(`/dashboard/data-gap`);
      data.dataGap = resp.data;
    };

    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    let optionsFrom = (date) => {
      return (
        date <= moment().format("YYYY/MM/DD") &&
        date >= moment(data.dataGap.min).format("YYYY/MM/DD")
      );
    };

    let optionsTo = (date) => {
      return (
        date <= moment().format("YYYY/MM/DD") &&
        date >= moment(data.date_from).format("YYYY/MM/DD")
        // &&
        // date <= moment(data.date_from).add(31, "days").format("YYYY/MM/DD")
      );
    };

    let exportExcell = async () => {
      let resp = await $http.get(
        `/dashboard/export/${moment(data.date_from).format(
          "YYYY-MM-DD"
        )}/${moment(data.date_to).format("YYYY-MM-DD")}`
      );

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet();

      let summaryDown = {};

      let columns = [
        { header: "No", key: "no", width: 5 },
        { header: "Code", key: "code", width: 10 },
        { header: "Color", key: "color", width: 10 },
      ];
      let dif = moment(data.date_to).diff(data.date_from, "day") + 1;
      for (let i = 0; i < dif; i++) {
        let date = moment(data.date_from).add(i, "days").format("DD MMM 'YY");
        let datekey = moment(data.date_from)
          .add(i, "days")
          .format("YYYY_MM_DD");
        columns.push({ header: date, key: datekey, width: 10 });
        summaryDown[datekey] = 0;
      }

      columns.push({ header: "Total", key: "total", width: 10 });
      sheet.columns = columns;

      for (let i in resp.data) {
        let sku = resp.data[i];
        // {no:i+1, code:sku.kode, color:sku.color}
        let row = sku.sales.reduce((acc, { tanggal, jumlah }) => {
          acc[moment(tanggal).format("YYYY_MM_DD")] = jumlah;
          summaryDown[moment(tanggal).format("YYYY_MM_DD")] += jumlah;
          return acc;
        }, {});

        let total = await sumArrayofObject(sku.sales, "jumlah");

        row.no = parseInt(i) + 1;
        row.code = sku.kode;
        row.color = sku.warna;
        row.total = total;

        sheet.addRow(row);
        sheet.getCell;
      }
      sheet.addRow(summaryDown);
      sheet.properties.outlineProperties = {
        summaryBelow: false,
        summaryRight: false,
      };

      let columnKey = await getExcellColumn(dif + 4);
      let rowKey = resp.data.length + 2;
      let column = sheet.getColumn(columnKey);
      let row = sheet.getRow(rowKey);

      column.font = { bold: true };
      row.font = { bold: true };

      column.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E6E6E6" },
      };

      row.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E6E6E6" },
      };

      sheet.mergeCells(`A${rowKey}:C${rowKey}`);
      sheet.getCell(`A${rowKey}`).value = "Total";
      sheet.getCell(`A${rowKey}`).alignment = { horizontal: "center" };

      const name = `Report ${moment(data.date_from).format(
        "DD MMM 'YY"
      )} - ${moment(data.date_to).format("DD MMM 'YY")}.xlsx`;
      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([buffer], { type: fileType });
      saveAs(blob, name);
      onOKClick();
    };

    let onOKClick = () => {
      onDialogOK();
    };

    return {
      data,
      optionsFrom,
      optionsTo,
      dialogRef,
      onDialogHide,

      onCancelClick: onDialogCancel,
      exportExcell,
    };
  },
};
</script>
