<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-gutter-y-sm">
        <div class="row items-center">
          <div style="width: 100px">Nomor Kolom</div>
          <q-input class="l-grow" outlined dense v-model="data.num_column">
          </q-input>
        </div>
        <div class="row items-center">
          <div style="width: 100px">Huruf Kolom</div>
          <q-input
            class="l-grow"
            outlined
            dense
            readonly
            v-model="data.char_column"
          >
          </q-input>
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn color="primary" label="Hitung" @click="getColumn" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent } from "quasar";
import useBasic from "@/composables/useBasic";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default {
  props: [],

  emits: [...useDialogPluginComponent.emits],

  setup(props) {
    const {
      $http,
      onMounted,
      reactive,
      moment,
      waitThis,
      sumArrayofObject,
      excelColumn,
    } = useBasic();

    onMounted(async () => {});

    let data = reactive({
      num_column: 0,
      char_column: "-",
    });

    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    let onOKClick = () => {
      onDialogOK();
    };

    let getColumn = () => {
      let result = "";
      let digit = data.num_column;
      while (digit > 0) {
        let num = digit - 1;
        let sisa = num % 26;
        result = `${String.fromCharCode(65 + sisa)}${result}`;
        digit = Math.floor((digit - 1) / 26);
      }
      data.char_column = result;
    };

    return {
      data,

      dialogRef,
      onDialogHide,

      onCancelClick: onDialogCancel,
      getColumn,
    };
  },
};
</script>
