<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" persistent>
    <q-card class="q-dialog-plugin border-card" style="min-width: 300px">
      <q-card-section class="row justify-between items-center">
        <div>
          <div class="text-h6 text-bold">Ubah Password</div>
          <div class="l-text-description text-grey-7">
            Masukan password baru Anda
          </div>
        </div>
        <q-btn flat icon="close" @click="onCancelClick"></q-btn>
      </q-card-section>

      <q-separator></q-separator>
      <q-card-section class="column q-gutter-y-sm">
        <div class="column q-gutter-xs">
          <label class="text-bold"> Password Baru :</label>
          <q-input
            outlined
            v-model="setPwd.new"
            :type="isPwdNew ? 'password' : 'text'"
            ref="newpwd"
            dense
            ><template v-slot:append>
              <q-icon
                :name="isPwdNew ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwdNew = !isPwdNew"
              /> </template
          ></q-input>
        </div>
        <div class="column q-gutter-xs">
          <label class="text-bold"> Konfirmasi Password Baru :</label>
          <q-input
            outlined
            v-model="setPwd.conf"
            ref="confpwd"
            dense
            :type="isPwdConf ? 'password' : 'text'"
            ><template v-slot:append>
              <q-icon
                :name="isPwdConf ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwdConf = !isPwdConf"
              /> </template
          ></q-input>
        </div>
      </q-card-section>
      <q-card-section class="bg-grey-3" style="padding-top: 16px !important">
        <q-btn
          unelevated
          no-caps
          class="full-width"
          color="brand"
          label="SIMPAN"
          @click="waitThis(checkPassword)"
        ></q-btn>
      </q-card-section>
      <q-dialog v-model="dialogName" persistent>
        <q-card>
          <q-card-section>
            <q-input outlined label="Name" v-model="name"></q-input>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              flat
              label="Cancel"
              no-caps
              @click="
                name = curUser.name;
                dialogName = false;
              "
            ></q-btn>
            <q-btn
              unelevated
              label="Ubah"
              color="brand"
              no-caps
              @click="changeName"
              :disable="name == '' || name == curUser.name"
            ></q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { inject, onMounted, ref } from "vue";
import md5 from "crypto-js/md5";
import useBasic from "@/composables/useBasic";
export default {
  emits: [...useDialogPluginComponent.emits],

  setup() {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    const { waitThis, onMounted, route, $http, ref, $q } = useBasic();
    let setPwd = ref({
      cur: "",
      new: "",
      conf: "",
    });

    onMounted(() => {
      curUser.value = JSON.parse(JSON.stringify(route.meta.user));
      name.value = curUser.value.name;
    });

    let curUser = ref({});

    let curpwd = ref(null);
    let newpwd = ref(null);
    let confpwd = ref(null);

    let checkPassword = async () => {
      if (setPwd.value.new == "") {
        newpwd.value.focus();
        $q.notify({
          message: "Password Baru tidak boleh kosong",
          color: "negative",
        });
        return;
      }
      if (setPwd.value.conf != setPwd.value.new) {
        confpwd.value.focus();
        $q.notify({
          message: "Konfirmasi Password tidak sama dengan Password Baru",
          color: "negative",
        });
        return;
      }

      await saveNewPassword();
      // try {
      //   let payload = {
      //     username: curUser.value.username,
      //     password: await md5(setPwd.value.cur).toString(),
      //   };
      //   let resp = await $http.post("/user/login", payload);
      //   if (resp.data) {
      //   }
      // } catch (error) {
      //   $q.notify({ message: "Password Lama Salah", color: "negative" });
      //   curpwd.value.focus();
      // }
    };

    let saveNewPassword = async () => {
      try {
        let payload = {
          id: curUser.value.id,
          password: await md5(setPwd.value.new).toString(),
        };
        await $http.post(`/user/change-password`, payload);
        $q.notify({ message: "Password Berhasil Dirubah", color: "positive" });
        setPwd.value.cur = "";
        setPwd.value.new = "";
        setPwd.value.conf = "";
        onOKClick();
      } catch (err) {
        $q.notify({ message: "Password Gagal Dirubah", color: "negative" });
      }
    };

    let onOKClick = () => {
      onDialogOK();
    };

    let dialogName = ref(false);
    let name = ref(null);
    let changeName = async () => {
      try {
        $q.loading.show();
        let payload = {
          id: curUser.value.id,
          name: name.value,
        };
        await $http.post(`/user/change-name`, payload);
        dialogName.value = false;
        $q.notify({
          message:
            "Nama Berhasil Dirubah! Silahkan login ulang pada aplikasi untuk memperbaharui nama!",
          color: "positive",
        });
        $q.loading.hide();
      } catch (err) {
        $q.notify({ message: "Nama Gagal Dirubah!", color: "negative" });
        $q.loading.hide();
      }
    };

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      isPwdCur: ref(true),
      isPwdNew: ref(true),
      isPwdConf: ref(true),
      setPwd,
      route,
      checkPassword,
      curpwd,
      newpwd,
      confpwd,
      waitThis,
      dialogName,
      name,
      changeName,
      curUser,
    };
  },
};
</script>
